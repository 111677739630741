class PopupMessage {
  async sendMessageToContent(action, params) {
    try {
      const tabs = await window.chrome.tabs.query({
        active: true,
        currentWindow: true,
      });
      const response = await window.chrome.tabs.sendMessage(tabs[0].id, {
        action: action,
        params: params,
      });
      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async sendMessageToBackground(data) {
    return new Promise((resolve, reject) => {
      window.chrome.runtime.sendMessage(data, (response) => {
        resolve(response);
      });
    });
  }
}

let popupMessage = new PopupMessage();
export default popupMessage;
