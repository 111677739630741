import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// const firebase = require("firebase/app");
require("firebase/auth");

const config = {
  apiKey: "AIzaSyDcNJdMUDviLmJfeZTH219-rzG3OlZGNqc",
  authDomain: "spheric-basis-377008.firebaseapp.com",
  projectId: "spheric-basis-377008",
  storageBucket: "spheric-basis-377008.appspot.com",
  messagingSenderId: "664081184629",
  appId: "1:664081184629:web:94424cbe1aad22e616053c",
  measurementId: "G-SJ47MXF6P9",
};

firebase.initializeApp(config);
const firebaseAuth = firebase.auth();

export { firebaseAuth };
