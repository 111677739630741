import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

export default function About() {
  const [num, setNum] = useState(0);
  useEffect(() => {
    axios.post("api/about").then((res) => {
      console.log(res);
      if (res.count) {
        setNum(res.count);
      }
    });
  }, []);
  return (
    <>
      <button>The open number is :{num}</button>
    </>
  );
}
