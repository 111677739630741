import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toaster } from "evergreen-ui";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import BrandLogo from "../media/brandlogo.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const { firebaseAuth } = require("./firebaseTool");

export default function SignUp(props) {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(function (props) {
    document.title = "SignUp";
  });
  function SignUp(e) {
    // sign up with verify email
    setLoading("is-loading");

    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        setLoading("");
        // Signed in
        var user = userCredential.user;
        if (user) {
          localStorage.setItem("email", user.email);
          localStorage.setItem("uid", user.uid);
          // send email verification and if success, navigate to / page
          user.sendEmailVerification().then(function () {
            // Email sent.
            toaster.success(
              "Please verify your email, and then you can sign in",
              { duration: 5 }
            );
            setTimeout(() => {
              navigate("/login");
            }, 5000);
          });
        }
      })
      .catch((error) => {
        setLoading("");
        var errorCode = error.code;
        console.log(error);
        if (errorCode === "auth/email-already-in-use") {
          toaster.danger("Email already in use");
        } else if (errorCode === "auth/invalid-email") {
          toaster.danger("Invalid email");
        } else if (errorCode === "auth/weak-password") {
          toaster.danger("Weak password");
        } else {
          toaster.danger("Sign up failed");
        }
      });
  }

  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // 绿色 green beach
          //   background: "linear-gradient(to right, #02aab0, #00cdac)",
          //   emerald water
          //   background: "linear-gradient(to right, #348f50, #56b4d3)",
          //   mojito
          //   background: "linear-gradient(to right, #1d976c, #93f9b9)",
          // sea weed
          background:
            "linear-gradient(-225deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%)",
          // background-image:
          //   background: "linear-gradient(to top, #7028e4 0%, #e5b2ca 100%)",
          //   sea blizz
          //   background: "linear-gradient(to right, #1cd8d2, #93edc7)",
          //   蓝色 skyline
          //   background: "linear-gradient(to right, #1488cc, #2b32b2)",
        }}
      >
        <div className="hero-head">
          <div className="container mt-6">
            <Link
              to="/"
              className="button has-text-white is-rounded"
              style={{
                background: "transparent",
              }}
            >
              <ArrowBackIosIcon />
              Back to home
            </Link>
          </div>
        </div>
        <div className="hero-body">
          <div
            className="container"
            style={{
              marginTop: "-160px",
            }}
          >
            <div className="columns is-centered is-multiline">
              <div className="column is-12">
                <div className="container has-text-centered">
                  <img
                    src={BrandLogo}
                    alt="brand logo"
                    style={{ maxWidth: "100%", height: "70px" }}
                  />
                  <h1
                    className="title has-text-white"
                    style={{
                      fontSize: "30px",
                      fontWeight: "900",
                      lineHeight: "1.1",
                      marginBottom: "0px",
                      // marginTop: "auto",
                    }}
                  >
                    Welcome back.
                  </h1>
                  <p className="content has-text-white">
                    Sign into your account.
                  </p>
                </div>
              </div>
              <div className="box">
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="your email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <span className="icon is-small is-left">
                      <EmailIcon />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="password"
                      placeholder="your password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <span className="icon is-small is-left">
                      <LockIcon />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <button
                    className={`button is-rounded is-fullwidth ${loading}`}
                    onClick={SignUp}
                    style={{
                      background:
                        "linear-gradient(to right, #DA22FF 0%, #9733EE 0%, #DA22FF  100%)",
                      color: "white",
                    }}
                  >
                    Sign up
                  </button>
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <Link
                          to="/login"
                          className="help is-link has-text-link"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        <Link
                          to="/forget-password"
                          className="help is-link has-text-link"
                        >
                          Forget password
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
