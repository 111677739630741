import React from "react";
import "bulma/css/bulma.css";
import FeatureOne from "./feature-one";
import FeatureHistory from "./feature-history";
import FeatureProductivity from "./feature-productivity";
import FeatureData from "./feature-data";
import ChromeLogo from "../media/chrome.png";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

export default function Home() {
  return (
    <>
      <FeatureOne />
      <FeatureHistory />
      <FeatureProductivity />
      <FeatureData />
      <div
        className="container has-text-centered"
        style={{
          backgroundColor: "#f5f5f5",
          height: "220px",
          paddingTop: "50px",
        }}
      >
        <h1
          className="title"
          style={{
            fontSize: "50px",
            fontWeight: "900",
            lineHeight: "1.1",
          }}
        >
          All your clipboard in one place
        </h1>
        <a
          href="mailto:hey@shareclip.me"
          className="button"
          style={{
            fontWeight: "bold",
          }}
        >
          <span className="icon">
            <EmailIcon />
          </span>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            Contact Us
          </span>
        </a>
      </div>
    </>
  );
}
