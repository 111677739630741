import { useState, useEffect } from "react";
import { toaster } from "evergreen-ui";
import Navbar from "./navbar";
var data = [
  // {
  //   list: [
  //     { content: "Upto 3,000 chars per convert", isHeighted: false },
  //     { content: "200,000 characters per month", isHeighted: false },
  //     { content: "No subscription", isHeighted: false },
  //     { content: "No Ads", isHeighted: false },
  //     { content: "Support SSML", isHeighted: false },
  //     { content: "One time purchase", isHeighted: false },
  //     { content: "Personal & Commercial use", isHeighted: false },
  //     { content: "Email Support", isHeighted: false },
  //   ],
  //   price: "10",
  //   name: "Basic",
  //   isMostPopular: false,
  //   itemId: "1",
  // },
  {
    list: [
      { content: "No limited devices", isHeighted: true },
      { content: "No limited content number", isHeighted: false },
      { content: "New features priority experience", isHeighted: false },
      { content: "7x24 support", isHeighted: false },
      // { content: "No Ads", isHeighted: false },
      // { content: "Support SSML", isHeighted: false },
      // { content: "One time purchase", isHeighted: false },
      // { content: "Personal & Commercial use", isHeighted: false },
      // { content: "Email Support", isHeighted: false },
    ],
    price: "10",
    name: "Premium",
    isMostPopular: true,
    itemId: "1",
    productId: "804298",
  },
  // {
  //   list: [
  //     { content: "Upto 3,000 chars per convert", isHeighted: true },
  //     { content: "200,000 characters per month", isHeighted: true },
  //     { content: "No subscription", isHeighted: false },
  //     { content: "No Ads", isHeighted: false },
  //     { content: "Support SSML", isHeighted: false },
  //     { content: "One time purchase", isHeighted: false },
  //     { content: "Personal & Commercial use", isHeighted: false },
  //     { content: "Email Support", isHeighted: false },
  //   ],
  //   price: "20",
  //   name: "Business",
  //   isMostPopular: false,
  //   itemId: "1",
  // },
];

var dataYearly = [
  // {
  //   list: [
  //     { content: "Upto 3,000 chars per convert", isHeighted: false },
  //     { content: "200,000 characters per month", isHeighted: false },
  //     { content: "No subscription", isHeighted: false },
  //     { content: "No Ads", isHeighted: false },
  //     { content: "Support SSML", isHeighted: false },
  //     { content: "One time purchase", isHeighted: false },
  //     { content: "Personal & Commercial use", isHeighted: false },
  //     { content: "Email Support", isHeighted: false },
  //   ],
  //   price: "10",
  //   name: "Basic",
  //   isMostPopular: false,
  //   itemId: "1",
  // },
  {
    list: [
      { content: "No limited devices", isHeighted: true },
      { content: "20% discount", isHeighted: true },
      { content: "No limited content number", isHeighted: false },
      { content: "New features priority experience", isHeighted: false },
      { content: "7x24 support", isHeighted: false },
    ],
    price: "96",
    name: "Premium",
    isMostPopular: true,
    itemId: "1",
    productId: "804295",
  },
  // {
  //   list: [
  //     { content: "Upto 3,000 chars per convert", isHeighted: true },
  //     { content: "200,000 characters per month", isHeighted: true },
  //     { content: "No subscription", isHeighted: false },
  //     { content: "No Ads", isHeighted: false },
  //     { content: "Support SSML", isHeighted: false },
  //     { content: "One time purchase", isHeighted: false },
  //     { content: "Personal & Commercial use", isHeighted: false },
  //     { content: "Email Support", isHeighted: false },
  //   ],
  //   price: "20",
  //   name: "Business",
  //   isMostPopular: false,
  //   itemId: "1",
  // },
];

export default function Price() {
  // useEffect(() => {
  //   const Paddle = window.Paddle;
  // });

  const [currentSelectPlan, setCurrentSeelctPlan] = useState("m");
  const PriceItem = (props) => (
    <div
      className="column is-4"
      style={
        props.isMostPopular
          ? {
              backgroundColor: "#41C98F",
              borderRadius: "10px 10px 10px 10px",
            }
          : {}
      }
    >
      <div className="container">
        <div className="subtitle is-4 has-text-weight-bold has-text-centered has-text-white">
          {props.isMostPopular ? "Most Popular" : "&nbsp;"}
        </div>
        <div className="box has-text-left">
          <p className="title has-text-black has-text-weight-bold">
            {props.name}
          </p>
          <p className="title is-1 has-text-black has-text-weight-bold">
            ${props.price}
            <span className="has-text-grey-light text-gray-300 is-size-6">
              / month
            </span>
          </p>
          <div className="field" style={{ marginTop: "35px" }}>
            <div className="control has-text-centered">
              <button
                className={
                  props.isMostPopular
                    ? "button is-link is-rounded"
                    : "button is-link is-rounded is-outlined"
                }
                style={{ width: "60%" }}
                onClick={(e) => {
                  // console.log(props.itemId);
                  // toaster.success("Coming soon", { duration: 5 });
                  // console.log(props.productId);
                  const Paddle = window.Paddle;
                  Paddle.Checkout.open({ product: "818027" });
                }}
              >
                Get Started
              </button>
            </div>
          </div>
          <hr />

          <ul className="content ml-3" style={{ listStyleType: "disc" }}>
            {props.arr.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={
                    item.isHeighted
                      ? "has-text-weight-bold has-background-warning-light"
                      : ""
                  }
                >
                  {item.content}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );

  const listPrice = data.map((item, idx) => {
    return (
      <PriceItem
        name={item.name}
        price={item.price}
        arr={item.list}
        itemId={item.itemId}
        isMostPopular={item.isMostPopular}
        key={idx}
        productId={item.productId}
      />
    );
  });

  const yearlyPrice = dataYearly.map((item, idx) => {
    return (
      <PriceItem
        name={item.name}
        price={item.price}
        arr={item.list}
        itemId={item.itemId}
        isMostPopular={item.isMostPopular}
        key={idx}
        productId={item.productId}
      />
    );
  });

  return (
    <div className="section">
      <Navbar />
      <h1
        className="title is-1 has-text-centered"
        style={{ marginTop: "65px" }}
      >
        Plans & Pricing
      </h1>
      <div
        className="buttons has-addons is-centered"
        style={{ marginTop: "60px" }}
      >
        <button
          className={
            currentSelectPlan === "m"
              ? "button is-link is-rounded"
              : "button is-rounded"
          }
          onClick={(e) => {
            setCurrentSeelctPlan("m");
          }}
        >
          Monthly
        </button>
        <button
          className={
            currentSelectPlan === "y"
              ? "button is-rounded is-link"
              : "button is-rounded"
          }
          onClick={(e) => {
            setCurrentSeelctPlan("y");
          }}
        >
          Yearly
        </button>
      </div>
      <div className="columns is-centered mt-6">
        <div className="column is-7">
          <div className="columns is-centered is-multiline">
            {currentSelectPlan === "m" ? listPrice : yearlyPrice}
          </div>
        </div>
      </div>
    </div>
  );
}
