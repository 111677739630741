import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toaster } from "evergreen-ui";
import EmailIcon from "@mui/icons-material/Email";
import BrandLogo from "../media/brandlogo.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const { firebaseAuth } = require("./firebaseTool");

export default function ForgetPassword(props) {
  let navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(function (props) {
    document.title = "Forget Password";
  });
  function ForgetEvent(e) {
    // reset password
    setLoading("is-loading");
    firebaseAuth
      .sendPasswordResetEmail(userName)
      .then(function () {
        setLoading("");
        toaster.success("Reset password email sent", { duration: 3 });
        let timerID = setInterval(() => {
          navigate(`/login`);
          clearInterval(timerID);
        }, 3000);
      })
      .catch(function (error) {
        setLoading("");
        console.log(`reset password error : ${error}`);
        var errorCode = error.code;
        if (errorCode === "auth/user-not-found") {
          toaster.danger("User not found");
        } else if (errorCode === "auth/invalid-email") {
          toaster.danger("Invalid email");
        } else {
          toaster.danger("Reset password failed");
        }
      });
  }

  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // sea weed
          background:
            "linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)",
        }}
      >
        <div className="hero-head">
          <div className="container mt-6">
            <Link
              to="/"
              className="button has-text-white is-rounded"
              style={{
                background: "transparent",
              }}
            >
              <ArrowBackIosIcon />
              Back to home
            </Link>
          </div>
        </div>
        <div className="hero-body">
          <div
            className="container"
            style={{
              marginTop: "-160px",
            }}
          >
            <div className="columns is-centered is-multiline">
              <div className="column is-12">
                <div className="container has-text-centered">
                  <img
                    src={BrandLogo}
                    alt="brand logo"
                    style={{ maxWidth: "100%", height: "70px" }}
                  />
                  <h1
                    className="title has-text-white"
                    style={{
                      fontSize: "30px",
                      fontWeight: "900",
                      lineHeight: "1.1",
                      marginBottom: "0px",
                      // marginTop: "auto",
                    }}
                  >
                    Welcome back.
                  </h1>
                  <p className="content has-text-white">
                    Sign into your account.
                  </p>
                </div>
              </div>
              <div className="box mt-5">
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="your email"
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                    <span className="icon is-small is-left">
                      <EmailIcon />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <button
                    className={`button is-rounded is-link is-fullwidth ${loading}`}
                    onClick={ForgetEvent}
                  >
                    Reset password
                  </button>
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <Link
                          to="/login"
                          className="help is-link has-text-link"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        <Link
                          to="/signup"
                          className="help is-link has-text-link"
                        >
                          Sign up
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
