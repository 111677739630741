import React from "react";
import { Link } from "react-router-dom";
import HeroIcon from "../media/hero-feature.png";
import ChromeLogo from "../media/chrome.png";

export default function FeatureProductivity(props) {
  return (
    <React.Fragment>
      <div className="container mt-6"></div>
      <div className="columns is-vcentered">
        <div
          className="column is-5"
          style={{
            fontSize: "60px",
            fontWeight: "900",
            lineHeight: "1.1",
            // marginTop: "auto",
          }}
        >
          <p className="has-text-info">Boost </p>
          <p className="has-text-success">Your Productivity</p>
          <p className="has-text-info">with</p>
          <p className="has-text-success-dark">ShareClip</p>
          <div className="subtitle is-5 mt-1">
            ShareClip makes it easy to quickly copy and access frequently used
            text, improving your productivity and streamlining your workflow.
          </div>
        </div>
        <div className="column is-7 has-text-centered">
          <img src={HeroIcon} alt="hero" />
        </div>
      </div>
    </React.Fragment>
  );
}
