import React from "react";
import { Link } from "react-router-dom";
import HeroIcon from "../media/hero-feature.png";
import ChromeLogo from "../media/chrome.png";

export default function FeatureOne(props) {
  return (
    <React.Fragment>
      <div className="container mt-6"></div>
      <div className="columns is-vcentered">
        <div
          className="column is-5"
          style={{
            fontSize: "60px",
            fontWeight: "900",
            lineHeight: "1.1",
            // marginTop: "auto",
          }}
        >
          <p className="has-text-link">Your </p>
          <p className="has-text-success">Clipboard</p>
          <p className="has-text-link">on </p>
          <p className="has-text-primary-dark">Any Device</p>
          <div className="subtitle is-5 mt-1">
            ShareClip allows you to save and sync your clipboard content across
            all your devices, making it easy to access your copied text no
            matter where you are.
          </div>
          <Link
            to="https://chrome.google.com/webstore/detail/shareclip/bjdlcfpfliniiinfkedgmlnfgpodmkic"
            className="button"
            onClick={() => {}}
            style={{
              fontWeight: "bold",
            }}
          >
            <span className="icon">
              <img src={ChromeLogo} width="18" height="18"></img>
            </span>
            <span
              style={{
                fontSize: "15px",
              }}
            >
              Add to Chrome for free
            </span>
          </Link>
        </div>
        <div className="column is-7 has-text-centered">
          <img src={HeroIcon} alt="hero" />
        </div>
      </div>
    </React.Fragment>
  );
}
