import React from "react";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <React.Fragment>
      <footer className="footer has-text-white has-background-dark">
        <div className="columns is-centered">
          <div className="column is-7">
            <div className="columns is-multiline">
              {/* <div className="column is-4">
                <img
                  src="{% static 'api/images/ttsmp3_logo002.png' %}"
                  width="112"
                  height="28"
                />
                <p className="content has-text-grey-lighter">
                  Your best choice for converting text to speech with the
                  download as an mp3 file.
                </p>
              </div> */}
              <div className="column">
                <ul>
                  <li className="content">
                    <Link
                      to="/privacy"
                      target="_blank"
                      className="has-text-white"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="content">
                    <Link
                      to="/terms"
                      className="has-text-white"
                      target="_blank"
                    >
                      Terms of Service
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <ul>
                <li className="content">
                  <a id="footer-a-link-hover" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li className="content">
                  <a id="footer-a-link-hover" target="_blank">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a id="footer-a-link-hover" target="_blank">
                    How To Use?
                  </a>
                </li>
              </ul> */}
              {/* <div className="column is-4">
                <ul>
                  <li className="content">
                    <a id="footer-a-link-hover" target="_blank">
                      Blog
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* <div className="column is-12 has-text-centered mt-3">
                <div
                  style={{ height: "1px", backgroundColor: "#EAEFF5" }}
                ></div>
              </div> */}
              {/* <div className="column is-12 has-text-centered mt-3">
                <p className="content">
                  © 2020 - 2022 aiutils.co. All rights reserved.
                </p>
                <p className="content">
                  <a className="has-text-white" href="/" target="_blank">
                    Created by xxx
                  </a>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
