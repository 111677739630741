import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export default function AmzDownloaderPayPage() {

    const navigate = useNavigate();
    const [payStatus, setPayStatus] = useState('Please wait a monment...');
    const [isShowPayButton, setIsShowPayButton] = useState(false);


    const Paddle = window.Paddle;
    let priceId;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const p = urlParams.get('p');
    if (p === "m") {
        priceId = 'pri_01hzmcqwye65fz87754naf8sd0';
    } else if (p === "q") {
        priceId = 'pri_01hzmdaxktddvdq4g7z531nb19';
    } else if (p === "y") {
        priceId = 'pri_01hzmdc45bjzbn3vm2xrqy7ceb';
    }

    // 初始化 Paddle
    Paddle.Initialize({
        token: 'live_52bd80b24295d58ce66eb6fd8f9', // replace with a client-side token
        eventCallback: function (data) {
            console.log(data);
            if (data.name === 'checkout.closed') {
                const status = data.data.status;
                if (status === "completed") {
                    console.log('支付完成!');
                    navigate('/amz-downloader/pay-success');
                } else {
                    console.log('支付失败!');
                    setPayStatus('Pay failed, you can try again.')
                    setIsShowPayButton(true);
                }
            }
        }
    });

    function openCheckout() {
        if (!priceId) {
            console.error('Invalid payment type');
            return;
        }
        Paddle.Checkout.open({
            items: [{
                priceId,
                quantity: 1
            }],
        });
    }

    useEffect(() => {
        if (priceId) {
            openCheckout();
        }
    }, [priceId]);

    return (
        <div className='is-flex is-justify-content-center is-align-items-center is-flex-direction-column' style={{
            height: '100vh',
            backgroundColor: "#DCF2F1"
        }}>
            {isShowPayButton
                ?
                <button onClick={openCheckout} className='button is-primary is-large'>
                    Pay Now
                </button>
                :
                null
            }

            <p className='content mt-2 is-size-5 has-text-grey is-bold'>{payStatus}</p>
        </div>
    );
}
