import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function DebugPayPage() {

    const navigate = useNavigate();

    const [payStatus, setPayStatus] = useState('Please wait a monment...');
    const [isShowPayButton, setIsShowPayButton] = useState(false);


    const Paddle = window.Paddle;
    Paddle.Environment.set("sandbox");
    Paddle.Initialize({
        token: "test_7d279f61a3499fed520f7cd8c08", // replace with a client-side token
        // prints events to console for debugging
        eventCallback: function (data) {
            console.log(data);
            if (data.name === 'checkout.closed') {
                const status = data.data.status;
                if (status === "completed") {
                    console.log('支付完成!');
                    navigate('/amz-downloader/pay-success');
                } else {
                    console.log('支付失败!');
                    setPayStatus('Pay failed, you can try again.')
                    setIsShowPayButton(true);
                }
            }
        }
    });

// define items
    let itemsList = [
        {
            priceId: "pri_01gsz8ntc6z7npqqp6j4ys0w1w",
            quantity: 5
        },
        {
            priceId: "pri_01h1vjfevh5etwq3rb416a23h2",
            quantity: 1
        }
    ];

// define customer details
    let customerInfo = {
        email: "sam@example.com",
        address: {
            countryCode: "US",
            postalCode: "10021"
        }
    };

    let priceId;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const p = urlParams.get('p');
    console.log('url参数:', p);
    if (p === "m") {
        priceId = 'pri_01gsz8ntc6z7npqqp6j4ys0w1w';
    } else if (p === "q") {
        priceId = 'pri_01h1vjfevh5etwq3rb416a23h2';
    } else if (p === "y") {
        priceId = 'pri_01gsz8ntc6z7npqqp6j4ys0w1w';
    }


// open checkout
    function openCheckout() {
        if (!priceId) {
            console.error('Invalid payment type');
            return;
        }
        Paddle.Checkout.open({
            items: [{
                priceId,
                quantity: 1
            }],
        });
    }


    useEffect(() => {
        if (priceId) {
            openCheckout();
        }
    }, [priceId]);

    return <div className='is-flex is-justify-content-center is-align-items-center is-flex-direction-column' style={{
        height: '100vh',
        backgroundColor: "#DCF2F1"
    }}>
        {isShowPayButton
            ?
            <button onClick={openCheckout} className='button is-primary is-large'>
                Pay Now
            </button>
            :
            null
        }

        <p className='content mt-2 is-size-5 has-text-grey is-bold'>{payStatus}</p>
    </div>
}