export default function PaySuccessPage() {

    return (
        <div className='is-flex is-justify-content-center is-align-items-center is-flex-direction-column'
             style={{
                 backgroundColor: '#CDE8E5',
                 height: '100vh',
             }}
        >
            <h1 className='title is-1'>Payment Success</h1>
            <h2 className='content has-text-centered' style={{
                marginLeft: '10%',
                marginRight: '10%',
            }}>Thank you for your support! You can go ahead and close this page. As a Member, you
                now have unlimited access to our app. Enjoy! </h2>
        </div>
    );
}