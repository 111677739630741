import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toaster } from "evergreen-ui";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import BrandLogo from "../media/brandlogo.png";
import popupMessage from "../popupMsg.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const { firebaseAuth } = require("./firebaseTool");

export default function Login(props) {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  useEffect(function (props) {
    document.title = "Login";
    // navigate("/");
  });
  function login(e) {
    setLoading("is-loading");
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        console.log(user);
        if (user.emailVerified) {
          localStorage.setItem("email", user.email);
          localStorage.setItem("uid", user.uid);
          axios
            .post("/user/login", {})
            .then((response) => {
              setLoading("");
              console.log(response);
              let status = response.status;
              popupMessage
                .sendMessageToBackground({
                  action: "login",
                  uid: user.uid,
                  email: user.email,
                })
                .then((response) => {
                  console.log(`login result from background : ${response}`);
                  toaster.success(response.message, {
                    duration: 1.5,
                  });
                  setTimeout(() => {
                    navigate("/");
                  }, 1500);
                })
                .catch((err) => {
                  console.log(`login result from background : ${err}`);
                });
            })
            .catch((error) => {
              setLoading("");
              console.log(error);
              toaster.danger("Login failed");
            });
        } else {
          setLoading("");
          toaster.danger("Please verify your email");
        }
      })
      .catch((error) => {
        setLoading("");
        console.log(`login error : ${error}`);
        var errorCode = error.code;
        if (errorCode === "auth/user-not-found") {
          toaster.danger("User not found");
        } else if (errorCode === "auth/wrong-password") {
          toaster.danger("Wrong password");
        } else {
          toaster.danger("Login failed");
        }
      });
  }

  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // 绿色 green beach
          //   background: "linear-gradient(to right, #02aab0, #00cdac)",
          //   emerald water
          //   background: "linear-gradient(to right, #348f50, #56b4d3)",
          //   mojito
          //   background: "linear-gradient(to right, #1d976c, #93f9b9)",
          // sea weed
          background: "linear-gradient(to right, #4cb8c4, #3cd3ad)",
          //   sea blizz
          //   background: "linear-gradient(to right, #1cd8d2, #93edc7)",
          //   蓝色 skyline
          //   background: "linear-gradient(to right, #1488cc, #2b32b2)",
        }}
      >
        <div className="hero-head">
          <div className="container mt-6">
            <Link
              to="/"
              className="button has-text-white is-rounded"
              style={{
                background: "transparent",
              }}
            >
              <ArrowBackIosIcon />
              Back to home
            </Link>
          </div>
        </div>
        <div className="hero-body">
          <div
            className="container"
            style={{
              marginTop: "-160px",
            }}
          >
            <div className="columns is-centered is-multiline">
              <div className="column is-12">
                <div className="container has-text-centered">
                  <img
                    src={BrandLogo}
                    alt="brand logo"
                    style={{ maxWidth: "100%", height: "70px" }}
                  />
                  <h1
                    className="title has-text-white"
                    style={{
                      fontSize: "30px",
                      fontWeight: "900",
                      lineHeight: "1.1",
                      marginBottom: "0px",
                      // marginTop: "auto",
                    }}
                  >
                    Welcome back.
                  </h1>
                  <p className="content has-text-white">
                    Sign into your account.
                  </p>
                </div>
              </div>
              <div className="box mt-5">
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="your email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <span className="icon is-small is-left">
                      <EmailIcon />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="password"
                      placeholder="your password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <span className="icon is-small is-left">
                      <LockIcon />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <button
                    className={`button is-success is-rounded is-fullwidth ${loading}`}
                    onClick={login}
                  >
                    Login
                  </button>
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <Link
                          to="/forget-password"
                          className="help is-link has-text-link"
                        >
                          Forget password
                        </Link>
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        <Link
                          to="/signup"
                          className="help is-link has-text-link"
                        >
                          Sign up
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
