import React from "react";
import { Link } from "react-router-dom";
import HeroHistory from "../media/hero-history.png";
import ChromeLogo from "../media/chrome.png";

export default function FeatureHistory(props) {
  const gradientStyle = (colors) => {
    return {
      background: `linear-gradient(to right, ${colors.join(",")})`,
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      MozBackgroundClip: "text",
      MozTextFillColor: "transparent",
    };
  };

  return (
    <React.Fragment>
      <div className="container mt-6"></div>
      <div className="columns is-vcentered">
        <div className="column is-7 has-text-centered">
          <img
            src={HeroHistory}
            alt="hero"
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "600px",
            }}
          />
        </div>
        <div
          className="column is-5"
          style={{
            fontSize: "60px",
            fontWeight: "900",
            lineHeight: "1.1",
            // marginTop: "auto",
          }}
        >
          <p style={gradientStyle(["#2dd4bf", "#2dd4bf"])}>Review</p>
          <p style={gradientStyle(["#2dd4bf", "#2dd4bf"])}>Your Clipboard</p>
          <p className="has-text-link">history</p>
          <p
            className="has-text-primary-dark"
            style={gradientStyle(["#c084fc", "#9333ea"])}
          >
            Any Device
          </p>
          <div className="subtitle is-5 mt-1">
            ShareClip allows you to save and sync your clipboard content across
            all your devices, making it easy to access your copied text no
            matter where you are.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
