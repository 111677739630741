import React from "react";
import "bulma/css/bulma.css";
import Navbar from "./navbar";
import Footer from "./footer";
import { Outlet } from "react-router-dom";

export default function CommonSkeletion(props) {
  return (
    <div className="hero">
      <div className="hero-head">
        <Navbar />
      </div>
      <div className="hero-body mt-6">
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
